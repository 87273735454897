import React from "react";
import { Link } from "react-router-dom";

function TermsAndConditions() {
  return (
    <div>
      <header>
        <Link to="/">
          <img src="assets/LogoWhite.svg" alt="" />
        </Link>
        >
      </header>

      <div style={{ padding: "0 10%", color: "#E7E7E7;" }}>
        <h1>Terms &amp; Conditions of Tipcomp Limited</h1>

        <h2>1.0 Tipcomp Limited</h2>
        <p>
          Tipcomp Limited is a registered company, filed with Companies House.
          Tipcomp’s company number is: 12385849. You can view our company
          records here: https://beta.companieshouse.gov.uk/company/12385849.
        </p>

        <h2>2.0 The Platform</h2>
        <p>
          Tipcomp provides a public platform available via web-browsers, mobile
          apps & APIs. The platform provides a service that allows users to host
          public and private tipping competitions based on real life sporting
          events. Users are awarded virtual points that have no cash/monetary
          value.
        </p>

        <h2>3.0 About you</h2>
        <p>
          Our platform is for users who are 18 years old or older. If you are
          not 18 years old or older you must not use our platform. You may be
          asked to confirm your age. If you are unable to confirm your age your
          account may be removed. You may not use our service if it would be
          unlawful to do so in the country where you are situated. By using the
          platform you have agreed to all these terms and conditions.
        </p>

        <h2>4.0 Cash/Money, Entry Fees, Points, Prizes, Rewards & Gambling</h2>
        <p>
          Tipcomp does not handle cash/money from users in any form. Tipcomp
          does not accept any fees to enter any competition, they are all free.
          All points are virtual and can not be redeemed for cash/money or any
          other commodity. There are no cash/money prizes. Rewards are virtual
          and are in the form of virtual points and virtual badges. These can
          not be redeemed for cash/money or any other commodity. Tipcomp does
          not facilitate gambling of any kind, including: virtual events, real
          life sporting events, pool betting, sportsbook betting or exchange
          betting. The platform is ‘just for fun’.
        </p>
        <p>
          Tipcomp does not offer or facilitate access to real money daily
          fantasy sports functionality or sports betting.
        </p>

        <h2>5.0 Communication</h2>
        <h3>5.1 Methods and channels of communication to you</h3>
        <p>
          By creating an account with Tipcomp.app you agree to receive
          communications that you have subscribed to via telephone, email, SMS
          text, push notifications and other digital channels. You have the
          option to unsubscribe from these communication methods via your
          account settings.
        </p>

        <h3>5.2 Updates to these Terms and Conditions</h3>
        <p>
          By creating an account with Tipcomp you agree to receive email
          notifications if and when these terms and conditions should change.
        </p>

        <h2>6.0 Competition Process</h2>
        <h3>6.1 Competitions</h3>
        <p>
          A competition is based on real life sporting events such as football,
          horse racing or other events. A competition will have a number of
          specific sporting events. The sporting events are defined by the
          administrators of the competition. Once the sporting events are
          defined they cannot be changed.
        </p>

        <h3>6.2 Joining and participating</h3>
        <p>
          Before joining a competition, a user can review the competition.
          Before joining, users will be able to see the sporting events and the
          rules of the competition. Users can join public competitions,
          providing the maximum number of participants has not been reached or
          exceeded. Users can only join private competitions that they have been
          invited to or to which they have been given the secret joining code.
          Upon joining a competition, the administrators of that competition
          will be notified. A competition administrator can remove a user from a
          competition at any time.
        </p>

        <h3>6.4 Making Predictions</h3>
        <p>
          You can make predictions as to the outcome of real life sporting
          events such as football matches and horse races. All predictions must
          be made before the advertised start time of the event. Once you have
          made your prediction it can not be changed.
        </p>

        <h3>6.5 Odds Based Competitions - Prices/Odds & Points</h3>
        <p>
          Prices on-site are refreshed at least once every 5 minutes. Always
          ensure your predictions have been submitted correctly by checking them
          in your profile. You cannot change your predictions once they have
          been submitted. Predictions made will be settled using the starting
          prices for that selection in that event. These prices may be different
          than those displayed at the point of time when you submit your
          prediction. If your prediction fails, you will not be awarded any
          points. If your prediction is true, the points you are awarded will
          reflect the start price.
        </p>

        <h3>6.6 Points Based Competition</h3>
        <p>
          A specific number of points will be allocated for a successful
          prediction and a reduced number of points will be allocated for each
          way/place predictions for horse racing events. Other methods of
          scoring points may be available in different competitions; please see
          the rules for each competition you enter.
        </p>

        <h3>6.7 Settlement/updating of predictions</h3>
        <p>
          Settlement/updating of predictions and ranking positions will occur
          immediately after the sporting event ends, as long as there are no
          outage issues with our service providers.
        </p>

        <h3>6.8 Competition Prizes & Rewards</h3>
        <p>
          When a competition concludes, the winners will be allocated badges
          based on their finishing positions. No prizes, cash/money will be paid
          out. This is a ‘just for fun’ platform.
        </p>

        <h2>7.0 Information</h2>
        <h3>7.1 Information</h3>
        <p>
          Although we make every effort to ensure that all information displayed
          on-site (fixtures, prices, etc.) is correct, we rely on third parties
          for this information and cannot guarantee 100% accuracy at all times.
          Information of a sporting event should be used only as a guide - if
          you are unsure as to the accuracy of any information please let us
          know via the support@tipcomp.app.
        </p>

        <h2>7.2 Incorrect Information</h2>
        <p>
          Prices not being available or incorrect information on the platform
          may occur. Tipcomp is dependent on 3rd party data suppliers. Tipcomp
          will make every effort to ensure the information on the app is correct
          and/or corrected quickly. If you notice any information you feel is
          incorrect you should email support@tipcomp.app so that we can
          investigate any issues. We do not accept responsibility for any issues
          arising as a result of a third-party error.
        </p>

        <h2>8.0 Sporting Events</h2>
        <h3>8.1 Football Matches</h3>
        <p>
          For football matches we do not count extra time or penalty
          shoot-outs.If you make a prediction for a match that is subsequently
          voided, abandoned or rescheduled we will follow the rules of the
          relevant Football Association authority with regard to settlement of
          the event.
        </p>

        <p>
          When a match is played at a neutral venue, the team listed on the left
          will be classed as the home team for settlement purposes.
        </p>

        <h2>9.0 Complaints</h2>
        <p>
          Our intention is to provide you with a fun experience based on popular
          sporting events. If for any reason you are unsatisfied with
          Tipcomp.app, please contact us by emailing support@tipcomp.app. Please
          provide an explanation & contact details.
        </p>
      </div>

      <footer>
        <table style={{ width: "80%", margin: "auto", color: "#E7E7E7" }}>
          <tr>
            <td style={{ width: "50%", textAlign: "left" }}>
              <img
                style={{ height: "60px" }}
                src="assets/LogoWhite.svg"
                alt=""
              />
              <p>Tipcomp run tipping competitions for you and your friends</p>
            </td>
            <td>
              <h3>Useful Links</h3>
              <ul>
                <li>
                  <Link to="/privacy">Privacy</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                </li> 
              </ul>
            </td>
          </tr>
        </table>
      </footer>
    </div>
  );
}

export default TermsAndConditions;
