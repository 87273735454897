import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

// Firebase
import firebase from "firebase";
var firebaseConfig = {
  apiKey: "AIzaSyA4wxBoeGYZHJ3CR_RHsMBiS0epGncCiNc",
  authDomain: "tipcomp-c340c.firebaseapp.com",
  databaseURL: "https://tipcomp-c340c.firebaseio.com",
  projectId: "tipcomp-c340c",
  storageBucket: "tipcomp-c340c.appspot.com",
  messagingSenderId: "990568380219",
  appId: "1:990568380219:web:f790f320f24a4cbafbe91e",
  measurementId: "G-NSNBDSXXB3",
};
firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
