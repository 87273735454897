import React from "react";
import LogoWhite from "../assets/LogoWhite.svg";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
      <header>
        <Link to="/">
          <img src={LogoWhite} alt="" />
        </Link>
      </header>

      <div className="horse-background">
        <div className="parent">
          <div className="div1">
            <h2>Welcome to Tipcomp</h2>
            <p>
              Setting up and running a tipping competition with your friends can
              be a lot of work, we know, we've run then out of WhatsApp groups
              &amp; Excel spreadsheets for years.
            </p>
            <p>Tipcomp provides a app to handle it all for you!</p>
            <p>
              With Tipcomp, you can create a competitions around popular
              football or horse races, invite your friends and play.
            </p>
            <p>
              We handle the selections, people's predictions and award points
              for people that know there sports.
            </p>
            <p>
              We don't take money or bets, <strong>its just for fun.</strong>{" "}
            </p>
          </div>
        </div>
      </div>
      <div></div>
      <div className="football-background">
        <div>Coming Soon</div>
      </div>

      <footer>
        <table style={{ width: "80%", margin: "auto", color: "#E7E7E7" }}>
          <tbody>
            <tr>
              <td style={{ width: "50%", textAlign: "left" }}>
                <img style={{ height: "60px" }} src={LogoWhite} alt="" />

                <p>Tipcomp run tipping competitions for you and your friends</p>
              </td>
              <td>
                <h3>Useful Links</h3>
                <ul>
                  <li>
                    <Link to="/privacy">Privacy</Link>
                  </li>
                  <li>
                    <Link to="terms-and-conditions">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </footer>
    </div>
  );
}

export default Home;
