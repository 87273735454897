import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Public page imports
import Home from "./pages/home";
import Privacy from "./pages/privacy";
import TermsAndConditions from "./pages/terms-and-conditions";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
